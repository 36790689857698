import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './footer.css'

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="mb-3">
          <Col md={4}>
            <h5>About Us</h5>
            <p>
              We are a company dedicated to providing the best services. Follow us on our social platforms to stay updated.
            </p>
          </Col>
          <Col md={4}>
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li><a href="#" className="text-light">Home</a></li>
              <li><a href="#" className="text-light">Products</a></li>
              <li><a href="#" className="text-light">Contact</a></li>
              <li><a href="#" className="text-light">About</a></li>
            </ul>
          </Col>
          <Col md={4}>
            <h5>Subscribe to our Newsletter</h5>
            <Form className="d-flex">
              <Form.Control type="email" placeholder="Enter email" className="footer-form me-2" />
              <Button style={{backgroundColor:'black', border:'none',borderRadius:'5px'}}>Subscribe</Button>
            </Form>
          </Col>
        </Row>

        <Row className="pt-3 border-top">
          <Col md={6}>
            <p>&copy; 2024 Your Company. All rights reserved.</p>
          </Col>
          <Col md={6} className="text-md-end">
            <a href="#" className="text-light me-3">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="#" className="text-light me-3">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href="#" className="text-light me-3">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="#" className="text-light">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;


