import React, { useEffect, useState } from "react";
import "./header.css";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass,faList,faBagShopping,faHeart,faUser} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedCategory, setSelectedCategory] = useState("All Categories");
  const [showDropdown, setShowDropdown] = useState(true);

  useEffect(() => {
    const storedCategory = localStorage.getItem("selectedCategory");
    if (storedCategory) {
      setSelectedCategory(storedCategory);
      setShowDropdown(false);

    }
  }, []);
  useEffect(() => {
    if (location.pathname === "/") {
      setShowDropdown(true);
    }
  }, [location.pathname]);

  const handleSelect = (category) => {
    setSelectedCategory(category);
    localStorage.setItem("selectedCategory", category);
    setShowDropdown(false); // Hide dropdown on category select

    if (category === "GARMENTS") {
      navigate("/garments");
    } else if (category === "MOBILE ACCESSORIES") {
      navigate("/mobile");
    }
  };

  return (
    <>
      <div>
        <Navbar expand="lg" className="header">
          <Container fluid>
            <Navbar.Brand as={Link} to="/">
              <img
                src="https://i.pinimg.com/originals/01/ef/46/01ef460f31697f93ee299d696a918030.jpg"
                width="90"
                height="90"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
                navbarScroll
              >
                {/* Additional Nav Links can go here */}
              </Nav>
              <Form className="header-searchbox d-flex">
                <Form.Control
                  className="searcher"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  style={{ borderRadius: "8px" }}
                />
                <Button
                  style={{
                    width: "80px",
                    borderRadius: "8px",
                    backgroundColor: "black",
                    border: "none",
                  }}
                >
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </Button>
              </Form>
              <div className="login-register d-flex">
                <Button className="login-button" variant="link" href="/login">
                  LOGIN
                </Button>
                <Button
                  className="login-button"
                  variant="link"
                  href="/register"
                >
                  REGISTER
                </Button>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <div className="heading-icons d-flex">
        <div className="container ">
          {/* Conditionally render the dropdown or headlines */}
          {showDropdown ? (
            <Dropdown>
              <Dropdown.Toggle
                as="button"
                className="header-dropdown option-button"
              >
                <FontAwesomeIcon className="me-1" icon={faList} />
                Category
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-items">
                <Dropdown.Item
                  className="dropdown-items"
                  onClick={() => handleSelect("GARMENTS")}
                >
                  GARMENTS
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-items"
                  onClick={() => handleSelect("MOBILE ACCESSORIES")}
                >
                  MOBILE ACCESSORIES
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <div className="">
              {selectedCategory === "GARMENTS" ? (
                <div className="container">
                  <div className="row col-12 ">
                    <div className="col-4 justify-content-center align-content-center d-flex">
                      <div className="dropdown-container">
                        <a className="garmets-category">
                          <p className="garments-headline text-center mt-2">
                            MEN
                          </p>
                        </a>
                        <div className="dropdown-content">
                          <p className="garments-men">SHIRTS</p>
                          <p className="garments-men">T-SHIRTS</p>
                          <p className="garments-men">PANTS</p>
                          <p className="garments-men">JACKETS</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-4 justify-content-center align-content-center d-flex ">
                      <div className="dropdown-container">
                        <a className="garmets-category">
                          <p className="garments-headline text-center mt-2">
                            WOMEN
                          </p>
                        </a>
                        <div className="dropdown-content">
                          <p className="garments-men">SHIRTS</p>
                          <p className="garments-men">T-SHIRTS</p>
                          <p className="garments-men">PANTS</p>
                          <p className="garments-men">JACKETS</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-4 justify-content-center align-content-center d-flex ">
                      <div className="dropdown-container">
                        <a className="garmets-category">
                          <p className="garments-headline text-center mt-2">
                            KIDS
                          </p>
                        </a>
                        <div className="dropdown-content">
                          <p className="garments-men">SHIRTS</p>
                          <p className="garments-men">T-SHIRTS</p>
                          <p className="garments-men">PANTS</p>
                          <p className="garments-men">JACKETS</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : selectedCategory === "MOBILE ACCESSORIES" ? (
                <div className=" justify-content-center align-content-center d-flex">
                  <a className="garmets-category">
                    <p className="Mobile-headlines text-center">
                      MOBILE ACCESSORIES
                    </p>
                  </a>
                </div>
              ) : null}
            </div>
          )}
        </div>
        {/* Icons (Bag, Heart, User) aligned to the right */}
        <div className=" icons d-flex">
          <a href="/category">
            <FontAwesomeIcon className="me-3" icon={faBagShopping} />
          </a>
          <a href="/wishlist">
            <FontAwesomeIcon
              className="me-3"
              icon={faHeart}
              style={{ color: "#e80219" }}
            />
          </a>
          <FontAwesomeIcon icon={faUser} />
        </div>
      </div>
    </>
  );
}

export default Header;
