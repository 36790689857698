import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import "./login.css";
import Login from '../../../Assests/login image.jpg';
import { useNavigate } from "react-router-dom";


const SignIn = () => {
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }else{
      navigate('/')
    }

    setValidated(true);
  };

  return (
    <section className="ezy__signin5 light d-flex align-items-center justify-content-center min-vh-100" style={{marginTop:'70px'}}>
      <Container>
        <Row className="justify-content-center">
          <Col lg={8} className="py-5">
            <Card className="ezy__signin5-form-card">
              <Card.Body className="p-md-5">
                <Row>
                 

                
                  <Col md={6} className="d-flex align-items-center">
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                      className="w-100"
                    >
                      <h2 className="ezy__signin5-heading mb-4">
                        Welcome to <span className="login-heading">KLEEMS</span>
                      </h2>

                      <Form.Group className="mb-3">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter Email Address"
                          required
                        />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter Password"
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Referral Code</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter Referral Code"
                          required
                        />
                      </Form.Group>

                      <Form.Group className="mb-3 d-flex justify-content-between">
                        <Form.Check type="checkbox" label="Remember me" />
                        <Button variant="link" className="login-forget p-0">
                          Forgot your password?
                        </Button>
                      </Form.Group>

                      <Button
                        type="submit"
                        className="button-login w-100 mb-3"
                      >
                        Log In
                      </Button>

                      <p className="mb-0 text-md-end">
                        <span className="opacity-30 me-2">
                          Don't have an account?
                        </span>
                        <Button
                          variant="link"
                          className="p-0 text-dark text-decoration-none"
                          style={{fontWeight:"bolder"}}
                          href="/register"
                        >
                          Create account
                        </Button>
                      </p>
                    </Form>
                  </Col>
                  <Col md={6} className="d-none d-md-block">
                    <img
                      src={Login}
                      alt="Login Illustration"
                      className="img-fluid"
                      style={{ Width: "100%", height:'100%' }}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SignIn;
