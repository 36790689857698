import React from 'react'
import "./whatsapp.css"
import whatsapp from '../../Assests/whatsapp.png'

export default function Whatsapp() {
  return (
    <div className="whatsapp-button">
    <a href="https://wa.me/1234567890" target="_blank">
      <img className="whatsapp-icon"src={whatsapp} alt="WhatsApp" />
    </a>
  </div>
  )
}
