import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap"; // Import the Carousel component
import "./viewproducts.css";
import Women from "../../Assests/women.jpg";
import Button from "react-bootstrap/Button";
import { ButtonGroup, InputGroup, FormControl } from "react-bootstrap";

function ViewProduct() {
  // State to track if the screen is small enough for a mobile view
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    // Function to check the window width and update the state
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 760);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Initial check to set the correct view on component load
    handleResize();

    // Cleanup function to remove the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Quantity Selector component
  const QuantitySelector = () => {
    const [quantity, setQuantity] = useState(1);

    const increment = () => {
      setQuantity((prevQuantity) => prevQuantity + 1);
    };

    const decrement = () => {
      if (quantity > 1) {
        setQuantity((prevQuantity) => prevQuantity - 1);
      }
    };

    return (
      <>
        <h5 className="product-colors mt-4">QUANTITY</h5>
        <InputGroup className="quantity-selector mt-3">
          <ButtonGroup>
          <Button variant="outline-dark" onClick={decrement}>
              -
            </Button>
          </ButtonGroup>
          <FormControl
            value={quantity}
            readOnly
            className="text-center"
            style={{ maxWidth: "60px" }}
          />
          <ButtonGroup>
          <Button variant="outline-dark" onClick={increment}>
              +
            </Button>
           
          </ButtonGroup>
        </InputGroup>
      </>
    );
  };

  return (
    <>
      <div className="container">
        <div className="row">
          {/* Conditionally render carousel for mobile view or grid for larger screens */}
          {isMobileView ? (
            <div className="col-md-12">
              <Carousel>
                <Carousel.Item>
                  <img
                    className="d-block w-100 dresses"
                    src={Women}
                    alt="Dress 1"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100 dresses"
                    src={Women}
                    alt="Dress 2"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100 dresses"
                    src={Women}
                    alt="Dress 3"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100 dresses"
                    src={Women}
                    alt="Dress 4"
                  />
                </Carousel.Item>
              </Carousel>
            </div>
          ) : (
            // Grid layout for larger screens (non-mobile view)
            <div className="view-products col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <img className="dresses" src={Women} alt="Product 1" />
                </div>
                <div className="row-product col-md-6">
                  <img className="dresses" src={Women} alt="Product 2" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <img className="dresses" src={Women} alt="Product 3" />
                </div>
                <div className="row-product col-md-6">
                  <img className="dresses" src={Women} alt="Product 4" />
                </div>
                <div className="product-description mt-3">
                  <h4>Product Details</h4>
                  <hr />
                  <ul>
                    <li>
                      Pink banarasi saree Ethnic motifs woven design saree with
                      no border Has zari detail
                    </li>
                    <li>  Pink banarasi saree Ethnic motifs woven design saree with
                    no border Has zari detail</li>
                    <li>  Pink banarasi saree Ethnic motifs woven design saree with
                    no border Has zari detail</li>
                  </ul>
                </div>
              </div>
            </div>
          )}

          {/* Product details section (stays the same for all views) */}
          <div className="view-products col-md-6">
            <div className="viewproduct-details">
              <h3 className="product-name">Dress</h3>
              <h6 className="product-details">Ethnic dress for women</h6>
              <hr />
              <h4 className="product-price">
                MRP ₹1500 <del className="original"> ₹5500</del>
              </h4>
              <h5 className="product-colors mt-4">MORE COLORS</h5>
              <div className="colors mt-3 d-flex">
                <Button className="button-color" variant="outline-dark">
                  Dark
                </Button>
                <Button className="button-color" variant="outline-dark">
                  Dark
                </Button>
                <Button className="button-color" variant="outline-dark">
                  Dark
                </Button>
                <Button className="button-color" variant="outline-dark">
                  Dark
                </Button>
              </div>
              <h5 className="product-size mt-4">SELECT SIZE</h5>
              <div className="select-size mt-3 d-flex">
                <Button className="button-size" variant="outline-dark">
                  XS
                </Button>
                <Button className="button-size" variant="outline-dark">
                  S
                </Button>
                <Button className="button-size" variant="outline-dark">
                  M
                </Button>
                <Button className="button-size" variant="outline-dark">
                  L
                </Button>
                <Button className="button-size" variant="outline-dark">
                  XL
                </Button>
                <Button className="button-size" variant="outline-dark">
                  XXL
                </Button>
              </div>

              {/* Include the Quantity Selector */}
              <QuantitySelector />

              <div className="buying-options d-flex mt-5">
                <Button className="buying-button" variant="outline-warning">
                  Buy Now
                </Button>
                <Button className="buying-button" variant="outline-dark">
                  Add to Cart
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewProduct;
