import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./pages/Home/home/Home";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import SignIn from "./pages/Home/Login/Login";
import Register from "./pages/Home/register/Register";
import Garments from "./pages/garments/Garments";
import Mobile from "./pages/mobile assesories/Mobile";
import Category from "./pages/cateogry/Category";
import Wishlist from "./pages/wishlist/Wishlist";
import Whatsapp from "./components/whatsapp/Whatsapp";
import ViewProduct from "./pages/ViewProducts/ViewProduct";
import ViewMobileProducts from "./pages/mobileaccessories/Viewmobileproducts";


function Layout() {
  const location = useLocation();
  const currentPath = location.pathname;

  // Check if the current path is not "/login" or "/register"
  const showHeaderFooter = currentPath !== "/login" && currentPath !== "/register";

  return (
    <div className="app-container">
      {/* Show Header and Footer only if the current path is not "/login" or "/register" */}
      {showHeaderFooter && <Header />}
      
      {/* Main content of the page */}
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/register" element={<Register />} />
          <Route path="/garments" element={<Garments />} />
          <Route path="/mobile" element={<Mobile />} />
          <Route path="/category" element={<Category />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/viewproduct" element={<ViewProduct />} />
          <Route path="/viewmobileproduct" element={<ViewMobileProducts />} />
        </Routes>
        <Whatsapp />
      </div>

      {/* Footer will be at the bottom of the page */}
      {showHeaderFooter && <Footer />}
    </div>
  );
}

export default function AppWrapper() {
  return (
    <BrowserRouter>
      <Layout />
    </BrowserRouter>
  );
}
