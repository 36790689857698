import React from 'react'
import './garments.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping, faHeart } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';



function Garments() {
    const navigate = useNavigate();
  
    const handleDivClick = () => {
      navigate('/viewproduct');  // Change this to the path of your products page
    }
  return (
    <div>
      <section className="ezy__epgrid5 light">
    <div className="container">
      {/* <!-- row start --> */}
      <div className="row ezy__epgrid5-row text-center justify-content-xl-center mt-2">
        {/* <!-- card start --> */}
        <div className="col-md-6 col-lg-4 col-xl-3 px-2 my-3"  onClick={handleDivClick}>
          <div className="card ezy__epgrid5-card border-0 p-2">
            <div className="card-body ezy__epgrid5-card-img position-relative h-100">
              <a href="#!">
                <div className="ezy__epgrid5-fav-icon"><FontAwesomeIcon icon={faHeart} style={{color: "#f80d0d",}} /></div>
              </a>
              <div className="ezy__epgrid5-card-img d-flex justify-content-center align-items-center h-100 px-4">
                <a href="#!">
                  <img src="https://cdn.easyfrontend.com/pictures/products/couch3.png" alt="..." />
                </a>
              </div>
            </div>
  
            <div className="card-body text-start">
              <a href="#!">
                <h5 className="ezy__epgrid5-title">Modern Lounge Chair with Ottoman classNameic Designer Chair</h5>
              </a>
              <h5 className="ezy__epgrid5-price my-2">$864.00</h5>
              <div className="ezy__epgrid5-details d-flex justify-content-between align-items-center">
                
                <a href="#!">
                  <h5 className="ezy__epgrid5-cart"><FontAwesomeIcon icon={faCartShopping} style={{color: "#FFD43B",}} /></h5>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- card end -->
        <!-- card start --> */}
        <div className="col-md-6 col-lg-4 col-xl-3 px-2 my-3"onClick={handleDivClick}>
          <div className="card ezy__epgrid5-card border-0 p-2" >
            <div className="card-body ezy__epgrid5-card-img position-relative h-100">
              <a href="#!">
                <div className="ezy__epgrid5-fav-icon"><FontAwesomeIcon icon={faHeart} style={{color: "#f80d0d",}} /></div>
              </a>
              <div className="ezy__epgrid5-card-img d-flex justify-content-center align-items-center h-100 px-4">
                <a href="#!">
                  <img src="https://cdn.easyfrontend.com/pictures/products/bag3.png" alt="..." />
                </a>
              </div>
            </div>
  
            <div className="card-body text-start">
              <a href="#!">
                <h5 className="ezy__epgrid5-title">Luxury Tassel Small Messenger Bag For Women Lingge Embroidery</h5>
              </a>
              <h5 className="ezy__epgrid5-price my-2">$390.00</h5>
              <div className="ezy__epgrid5-details d-flex justify-content-between align-items-center">
                
                <a href="#!">
                  <h5 className="ezy__epgrid5-cart"><FontAwesomeIcon icon={faCartShopping} style={{color: "#FFD43B",}} /></h5>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- card end -->
        <!-- card start --> */}
        <div className="col-md-6 col-lg-4 col-xl-3 px-2 my-3" onClick={handleDivClick}>
          <div className="card ezy__epgrid5-card border-0 p-2">
            <div className="card-body ezy__epgrid5-card-img position-relative h-100">
              <a href="#!">
                <div className="ezy__epgrid5-fav-icon"><FontAwesomeIcon icon={faHeart} style={{color: "#f80d0d",}} /></div>
              </a>
              <div className="ezy__epgrid5-card-img d-flex justify-content-center align-items-center h-100 px-4">
                <a href="#!">
                  <img src="https://cdn.easyfrontend.com/pictures/ecommerce/headphone.png" alt="..." />
                </a>
              </div>
            </div>
  
            <div className="card-body text-start">
              <a href="#!">
                <h5 className="ezy__epgrid5-title">New classNameic Copper Alloy Smooth Metal Hoop Earrings For Woman</h5>
              </a>
              <h5 className="ezy__epgrid5-price my-2">$245.00</h5>
              <div className="ezy__epgrid5-details d-flex justify-content-between align-items-center">
             
                <a href="#!">
                  <h5 className="ezy__epgrid5-cart"><FontAwesomeIcon icon={faCartShopping} style={{color: "#FFD43B",}} /></h5>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- card end -->
        <!-- card start --> */}
        <div className="col-md-6 col-lg-4 col-xl-3 px-2 my-3" onClick={handleDivClick}>
          <div className="card ezy__epgrid5-card border-0 p-2">
            <div className="card-body ezy__epgrid5-card-img position-relative h-100">
              <a href="#!">
                <div className="ezy__epgrid5-fav-icon"><FontAwesomeIcon icon={faHeart} style={{color: "#f80d0d",}} /></div>
              </a>
              <div className="ezy__epgrid5-card-img d-flex justify-content-center align-items-center h-100 px-4">
                <a href="#!">
                  <img src="https://cdn.easyfrontend.com/pictures/products/sbag1.png" alt="..." />
                </a>
              </div>
            </div>
  
            <div className="card-body text-start">
              <a href="#!">
                <h5 className="ezy__epgrid5-title">Luxury Tassel Small Messenger Bag For Women Lingge Embroidery</h5>
              </a>
              <h5 className="ezy__epgrid5-price my-2">$145.00</h5>
              <div className="ezy__epgrid5-details d-flex justify-content-between align-items-center">
              
                <a href="#!">
                  <h5 className="ezy__epgrid5-cart"><FontAwesomeIcon icon={faCartShopping} style={{color: "#FFD43B",}} /></h5>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- card end -->
        <!-- card start --> */}
        <div className="col-md-6 col-lg-4 col-xl-3 px-2 my-3" onClick={handleDivClick}>
          <div className="card ezy__epgrid5-card border-0 p-2">
            <div className="card-body ezy__epgrid5-card-img position-relative h-100">
              <a href="#!">
                <div className="ezy__epgrid5-fav-icon"><FontAwesomeIcon icon={faHeart} style={{color: "#f80d0d",}} /></div>
              </a>
              <div className="ezy__epgrid5-card-img d-flex justify-content-center align-items-center h-100 px-4">
                <a href="#!">
                  <img src="https://cdn.easyfrontend.com/pictures/products/tshirt2.png" alt="..." />
                </a>
              </div>
            </div>
  
            <div className="card-body text-start">
              <a href="#!">
                <h5 className="ezy__epgrid5-title">Spring Autumn Kids Thin Sweater Boys Girls Clothes Cute Dinosaur</h5>
              </a>
              <h5 className="ezy__epgrid5-price my-2">$2120.00</h5>
              <div className="ezy__epgrid5-details d-flex justify-content-between align-items-center">
               
                <a href="#!">
                  <h5 className="ezy__epgrid5-cart"><FontAwesomeIcon icon={faCartShopping} style={{color: "#FFD43B",}} /></h5>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- card end -->
        <!-- card start --> */}
        <div className="col-md-6 col-lg-4 col-xl-3 px-2 my-3" onClick={handleDivClick}>
          <div className="card ezy__epgrid5-card border-0 p-2">
            <div className="card-body ezy__epgrid5-card-img position-relative h-100">
              <a href="#!">
                <div className="ezy__epgrid5-fav-icon"><FontAwesomeIcon icon={faHeart} style={{color: "#f80d0d",}} /></div>
              </a>
              <div className="ezy__epgrid5-card-img d-flex justify-content-center align-items-center h-100 px-4">
                <a href="#!">
                  <img src="https://cdn.easyfrontend.com/pictures/products/watch2.png" alt="..." />
                </a>
              </div>
            </div>
  
            <div className="card-body text-start">
              <a href="#!">
                <h5 className="ezy__epgrid5-title">Spring Autumn Kids Thin Sweater Boys Girls Clothes Cute Dinosaur</h5>
              </a>
              <h5 className="ezy__epgrid5-price my-2">$2540.00</h5>
              <div className="ezy__epgrid5-details d-flex justify-content-between align-items-center">
              
                <a href="#!">
                  <h5 className="ezy__epgrid5-cart"><FontAwesomeIcon icon={faCartShopping} style={{color: "#FFD43B",}} /></h5>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- card end -->
        <!-- card start --> */}
        <div className="col-md-6 col-lg-4 col-xl-3 px-2 my-3" onClick={handleDivClick}>
          <div className="card ezy__epgrid5-card border-0 p-2">
            <div className="card-body ezy__epgrid5-card-img position-relative h-100">
              <a href="#!">
                <div className="ezy__epgrid5-fav-icon"><FontAwesomeIcon icon={faHeart} style={{color: "#f80d0d",}} /></div>
              </a>
              <div className="ezy__epgrid5-card-img d-flex justify-content-center align-items-center h-100 px-4">
                <a href="#!">
                  <img src="https://cdn.easyfrontend.com/pictures/products/shoe1.png" alt="..." />
                </a>
              </div>
            </div>
  
            <div className="card-body text-start">
              <a href="#!">
                <h5 className="ezy__epgrid5-title">LAOCHRA White Shoes For Men Sneakers Leather Korean Style</h5>
              </a>
              <h5 className="ezy__epgrid5-price my-2">$1050.00</h5>
              <div className="ezy__epgrid5-details d-flex justify-content-between align-items-center">
                <a href="#!">
                  <h5 className="ezy__epgrid5-cart"><FontAwesomeIcon icon={faCartShopping} style={{color: "#FFD43B",}} /></h5>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- card end -->
        <!-- card start --> */}
        <div className="col-md-6 col-lg-4 col-xl-3 px-2 my-3" onClick={handleDivClick}>
          <div className="card ezy__epgrid5-card border-0 p-2">
            <div className="card-body ezy__epgrid5-card-img position-relative h-100">
              <a href="#!">
                <div className="ezy__epgrid5-fav-icon"><FontAwesomeIcon icon={faHeart} style={{color: "#f80d0d",}} /></div>
              </a>
              <div className="ezy__epgrid5-card-img d-flex justify-content-center align-items-center h-100 px-4">
                <a href="#!">
                  <img src="https://cdn.easyfrontend.com/pictures/products/sofa2.png" alt="..." />
                </a>
              </div>
            </div>
  
            <div className="card-body text-start">
              <a href="#!">
                <h5 className="ezy__epgrid5-title">Modern Lounge Chair with Ottoman classNameic Designer Chair</h5>
              </a>
              <h5 className="ezy__epgrid5-price my-2">$350.00</h5>
              <div className="ezy__epgrid5-details d-flex justify-content-between align-items-center">
           
                <a href="#!">
                  <h5 className="ezy__epgrid5-cart"><FontAwesomeIcon icon={faCartShopping} style={{color: "#FFD43B",}} /></h5>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- card end -->
        <!-- card start --> */}
        <div className="col-md-6 col-lg-4 col-xl-3 px-2 my-3" onClick={handleDivClick}>
          <div className="card ezy__epgrid5-card border-0 p-2">
            <div className="card-body ezy__epgrid5-card-img position-relative h-100">
              <a href="#!">
                <div className="ezy__epgrid5-fav-icon"><FontAwesomeIcon icon={faHeart} style={{color: "#f80d0d",}} /></div>
              </a>
              <div className="ezy__epgrid5-card-img d-flex justify-content-center align-items-center h-100 px-4">
                <a href="#!">
                  <img src="https://cdn.easyfrontend.com/pictures/products/lamp4.png" alt="..." />
                </a>
              </div>
            </div>
  
            <div className="card-body text-start">
              <a href="#!">
                <h5 className="ezy__epgrid5-title">Touch Rechargeable Bud Table Lamps LED Creative Mushroom</h5>
              </a>
              <h5 className="ezy__epgrid5-price my-2">$250.00</h5>
              <div className="ezy__epgrid5-details d-flex justify-content-between align-items-center">
              
                <a href="#!">
                  <h5 className="ezy__epgrid5-cart"><FontAwesomeIcon icon={faCartShopping} style={{color: "#FFD43B",}} /></h5>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- card end -->
        <!-- card start --> */}
        <div className="col-md-6 col-lg-4 col-xl-3 px-2 my-3" onClick={handleDivClick}>
          <div className="card ezy__epgrid5-card border-0 p-2">
            <div className="card-body ezy__epgrid5-card-img position-relative h-100">
              <a href="#!">
                <div className="ezy__epgrid5-fav-icon"><FontAwesomeIcon icon={faHeart} style={{color: "#f80d0d",}} /></div>
              </a>
              <div className="ezy__epgrid5-card-img d-flex justify-content-center align-items-center h-100 px-4">
                <a href="#!">
                  <img src="https://cdn.easyfrontend.com/pictures/products/chair2.png" alt="..." />
                </a>
              </div>
            </div>
  
            <div className="card-body text-start">
              <a href="#!">
                <h5 className="ezy__epgrid5-title">Side Chair Back Chair Fabric Upholstered Seat Chairs</h5>
              </a>
              <h5 className="ezy__epgrid5-price my-2">$846.00</h5>
              <div className="ezy__epgrid5-details d-flex justify-content-between align-items-center">
            <a href="#!">
                  <h5 className="ezy__epgrid5-cart"><FontAwesomeIcon icon={faCartShopping} style={{color: "#FFD43B",}} /></h5>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- card end -->
        <!-- card start --> */}
        <div className="col-md-6 col-lg-4 col-xl-3 px-2 my-3" onClick={handleDivClick}>
          <div className="card ezy__epgrid5-card border-0 p-2">
            <div className="card-body ezy__epgrid5-card-img position-relative h-100">
              <a href="#!">
                <div className="ezy__epgrid5-fav-icon"><FontAwesomeIcon icon={faHeart} style={{color: "#f80d0d",}} /></div>
              </a>
              <div className="ezy__epgrid5-card-img d-flex justify-content-center align-items-center h-100 px-4">
                <a href="#!">
                  <img src="https://cdn.easyfrontend.com/pictures/products/perfume1.png" alt="..." />
                </a>
              </div>
            </div>
  
            <div className="card-body text-start">
              <a href="#!">
                <h5 className="ezy__epgrid5-title">Men's Perfumes Sauvage Eau De Parfum Perfumes</h5>
              </a>
              <h5 className="ezy__epgrid5-price my-2">$825.00</h5>
              <div className="ezy__epgrid5-details d-flex justify-content-between align-items-center">
                <a href="#!">
                  <h5 className="ezy__epgrid5-cart"><FontAwesomeIcon icon={faCartShopping} style={{color: "#FFD43B",}} /></h5>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- card end -->
        <!-- card start --> */}
        <div className="col-md-6 col-lg-4 col-xl-3 px-2 my-3" onClick={handleDivClick}>
          <div className="card ezy__epgrid5-card border-0 p-2">
            <div className="card-body ezy__epgrid5-card-img position-relative h-100">
              <a href="#!">
                <div className="ezy__epgrid5-fav-icon"><FontAwesomeIcon icon={faHeart} style={{color: "#f80d0d",}} /></div>
              </a>
              <div className="ezy__epgrid5-card-img d-flex justify-content-center align-items-center h-100 px-4">
                <a href="#!">
                  <img src="https://cdn.easyfrontend.com/pictures/products/glass2.png" alt="..." />
                </a>
              </div>
            </div>
  
            <div className="card-body text-start">
              <a href="#!">
                <h5 className="ezy__epgrid5-title">ZUEE Retro Small Rectangle Sunglasses Women Vintage</h5>
              </a>
              <h5 className="ezy__epgrid5-price my-2">$135.00</h5>
              <div className="ezy__epgrid5-details d-flex justify-content-between align-items-center">
                <a href="#!">
                  <h5 className="ezy__epgrid5-cart"><FontAwesomeIcon icon={faCartShopping} style={{color: "#FFD43B",}} /></h5>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- card end --> */}
      </div>
      {/* <!-- row ends --> */}
    </div>
  </section>
  
  </div>
  )
}

export default Garments;