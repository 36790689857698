import React from "react";
import "./home.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css"; // Optional if you want to use default styles
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import mobile from "../../../Assests/mobile.jpg";
import clothes from "../../../Assests/young-woman-beautiful-red-dress.jpg";
import Carousel from "react-bootstrap/Carousel";
// import ExampleCarouselImage from 'components/ExampleCarouselImage';

function Home() {
  const images = [
    {
      src: "https://i.pinimg.com/736x/e4/07/bd/e407bd9869cbfbfcbf44b7c1e1378447.jpg",
      alt: "Image 1",
    },
    {
      src: "https://whitedahlia.co.in/cdn/shop/products/LaylaPinkFloralDress_3.jpg?v=1635589856&width=1024",
      alt: "Image 2",
    },
    {
      src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToOuwRCqxulNWJ9CcQuOE7SDuhgsOCZvG88g&s",
      alt: "Image 3",
    },
    {
      src: "https://static.wixstatic.com/media/faf1ba_e2900d1f728c47629bb981c3ad0033ac~mv2.jpg/v1/fill/w_526,h_658,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/faf1ba_e2900d1f728c47629bb981c3ad0033ac~mv2.jpg",
      alt: "Image 4",
    },
  ];
  return (
    <>
      <div className="home-page container">
        <div className="row ">
          <div className=" home-image col-md-6">
            <Carousel
              interval={2000}
              pause={false}
              controls={false}
              indicators={false}
            >
              <Carousel.Item>
                <img
                  className=" home-images"
                  src="https://t4.ftcdn.net/jpg/03/64/41/07/360_F_364410725_IFLJi9mHRoeZ8W2bcGX1sxVJka14RmwZ.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className=" home-images"
                  src="https://miro.medium.com/v2/resize:fit:1024/0*fDn1lS2z4zr8cNES.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className=" home-images"
                  src="https://www.chinatoindialogistics.com/wp-content/uploads/2019/11/21.jpg"
                  alt=""
                />
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="col-md-6">
            <div className="row">
            <Carousel
              interval={3000}
              pause={false}
              controls={false}
              indicators={false}
            >
              <Carousel.Item>
                <img
                  className=" home-subimage"
                  src="https://img.freepik.com/free-photo/multi-colored-garments-hanging-coathangers-boutique-store-generated-by-ai_188544-19854.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className=" home-subimage"
                  src="https://www.shafidi.com/data/category/SBJE-GARMENTS%20ITEM/SBJE-GARMENTS%20ITEM.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className=" home-subimage"
                  src="https://media.fashionnetwork.com/cdn-cgi/image/fit=contain,width=1000,height=1000,format=auto/m/9a59/1f2f/15ad/89ef/c014/903e/e88e/e70e/db77/d13f/d13f.jpeg"
                  alt=""
                />
              </Carousel.Item>
            </Carousel>
            </div>
            <div className="row">
            <Carousel
              interval={4000}
              pause={false}
              controls={false}
              indicators={false}
            >
              <Carousel.Item>
                <img
                  className=" home-subimage mt-3"
                  src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhiypyeJve_xKjilm5_EFH_bMk1XitH6_IWwAHiE9NmQt5WHdfxL_IIZiu2jgGs3zXLyomhQjTj3qQCaXdNLSkCVzyr4NMRPLBmSXM6wuH2LW4DNvFunzKA2x_6N3YFleymfg0lwXGhS-MDXCKmn4TOv15RtTz8uCvAgMiDuZvYwhENF-mK3W7hxc-isDs/s1600/Garment%20definition%20and%20meaning.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className=" home-subimage mt-3"
                  src="https://media.istockphoto.com/id/1034427464/photo/usb-charging-cables-for-smartphone-and-tablet.jpg?s=612x612&w=0&k=20&c=OCWuHn0R87-kJT4g1_F7SLE9eNR13Gm6hhNl7Xfq388="
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className=" home-subimage mt-3"
                  src="https://media.istockphoto.com/id/1309448738/photo/smartphone-white-wireless-earphones-and-smart-watch.jpg?s=612x612&w=0&k=20&c=Lo9x3GM8nZPaprcpdkgimDL04J6eYTPklq6IsqXoTJ0="
                  alt=""
                />
              </Carousel.Item>
            </Carousel>
              {/* <img
                className="home-subimage mt-3"
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhiypyeJve_xKjilm5_EFH_bMk1XitH6_IWwAHiE9NmQt5WHdfxL_IIZiu2jgGs3zXLyomhQjTj3qQCaXdNLSkCVzyr4NMRPLBmSXM6wuH2LW4DNvFunzKA2x_6N3YFleymfg0lwXGhS-MDXCKmn4TOv15RtTz8uCvAgMiDuZvYwhENF-mK3W7hxc-isDs/s1600/Garment%20definition%20and%20meaning.jpg"
                alt=""
              /> */}
            </div>
          </div>
        </div>
        <div>
          {/* 1st carousal */}
          <h2 className="homepage-heading">GARMENTS</h2>
          <div className="row">
            <div className=" view-image col-md-3">
              <Card.Img variant="top" className="view-images" src={clothes} />
              <Card.Body className="home-page-view ">
                <Button className="view-button" href="/garments">
                  View All
                  <FontAwesomeIcon className="header-icon" icon={faCaretDown} />
                </Button>
              </Card.Body>
            </div>
            <div className="carousal-container col-md-9">
              <Splide
                className="heghttest"
                options={{
                  type: "loop", // Loop the slides
                  perPage: 4, // Show 6 slides at once
                  perMove: 1, // Move one slide at a time
                  autoplay: true, // Enable auto-play
                  interval: 3000, // Auto-play interval (ms)
                  pauseOnHover: true, // Pause on hover
                  pagination: false, // Hide pagination dots
                  arrows: true, // Show navigation arrows
                  breakpoints: {
                    1200: {
                      perPage: 4, // Show 4 slides on medium screens
                    },
                    768: {
                      perPage: 2, // Show 2 slides on smaller screens
                    },
                    576: {
                      perPage: 1, // Show 1 slide on mobile
                    },
                  },
                }}
              >
                {images.map((image, index) => (
                  <SplideSlide key={index} className="carousal-image">
                    <img src={image.src} alt={image.alt} />
                  </SplideSlide>
                ))}
              </Splide>
            </div>
          </div>
        </div>
        {/* 2nd carousal */}
        <div>
          <h2 className="homepage-heading">MOBILE ACCESSORIES</h2>
          <div className="row">
            <div className=" view-image col-md-3  mb-3">
              <Card.Img variant="top" className="view-images" src={mobile} />
              <Card.Body className="home-page-view ">
                <Button className="view-button" href="/mobile">
                  View All
                  <FontAwesomeIcon className="header-icon" icon={faCaretDown} />
                </Button>
              </Card.Body>
            </div>
            <div className="carousal-container col-md-9">
              <Splide
                className="heghttest"
                options={{
                  type: "loop", // Loop the slides
                  perPage: 4, // Show 6 slides at once
                  perMove: 1, // Move one slide at a time
                  autoplay: true, // Enable auto-play
                  interval: 3000, // Auto-play interval (ms)
                  pauseOnHover: true, // Pause on hover
                  pagination: false, // Hide pagination dots
                  arrows: true, // Show navigation arrows
                  breakpoints: {
                    1200: {
                      perPage: 4, // Show 4 slides on medium screens
                    },
                    768: {
                      perPage: 2, // Show 2 slides on smaller screens
                    },
                    576: {
                      perPage: 1, // Show 1 slide on mobile
                    },
                  },
                }}
              >
                {images.map((image, index) => (
                  <SplideSlide key={index} className="carousal-image">
                    <img src={image.src} alt={image.alt} />
                  </SplideSlide>
                ))}
              </Splide>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
