import React from 'react'
import './category.css'

function Category() {
  return (
    <div>
      Add to bag
    </div>
  )
}

export default Category
